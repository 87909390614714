import RoleEntity from '@modules/roles/entity';

class PermissionsEntity {
  resource = '';

  permissions: RoleEntity[] = [];

  constructor(permissions: Partial<PermissionsEntity>) {
    if (!permissions) {
      return;
    }
    Object.assign(this, permissions);
  }

  static createListPermissions(listPermissions: Array<Partial<PermissionsEntity>>) {
    if (!Array.isArray(listPermissions)) {
      return [];
    }
    return listPermissions.map((permissions: Partial<PermissionsEntity>) => {
      return new PermissionsEntity(permissions);
    });
  }
}
export default PermissionsEntity;

export enum PermissionEnum {
  EventsCreate = 'EventsCreate',
  EventsDelete = 'EventsDelete',
  EventsUpdate = 'EventsUpdate',
  EventsView = 'EventsView',
  GiftsCreate = 'GiftsCreate',
  GiftsDelete = 'GiftsDelete',
  GiftsUpdate = 'GiftsCreate',
  GiftsView = 'GiftsView',
  QuestionsCreate = 'QuestionsCreate',
  QuestionsDelete = 'QuestionsDelete',
  QuestionsUpdate = 'QuestionsUpdate',
  QuestionsView = 'QuestionsView',
  QuizesCreate = 'QuizesCreate',
  QuizesReply = 'QuizesReply',
  RolesCreate = 'RolesCreate',
  RolesDelete = 'RolesDelete',
  RolesUpdate = 'RolesUpdate',
  RolesView = 'RolesView',
  SessionsCreate = 'SessionsCreate',
  SessionsView = 'SessionsView',
  UsersCreate = 'UsersCreate',
  UsersDelete = 'UsersDelete',
  UsersUpdate = 'UsersUpdate',
  UsersView = 'UsersView',
  ReportView = 'ReportView'
}
