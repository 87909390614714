import React from 'react';
import { IRouter } from '@routers/interface';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerQuestions: IRouter = {
  path: '/questions',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'questions.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <QuestionCircleOutlined />,
  },
  permissionCode: PermissionEnum.QuestionsView,
};
