export default {
  'setting.name': 'Cài đặt',
  'roles.title': 'Quản lý vai trò',
  'roles.name': 'Tên vai trò',
  'roles.rolePermissions': 'Quyền',
  'roles.permissionCodes': 'Quyền',
  'roles.createdAt': 'Ngày tạo',
  'roles.action': 'Hành động',
  'Per.Users.Create': 'Thêm user',
  'Per.Users.Delete': 'Xoá user',
  'Per.Users.Edit': 'Sửa user',
  'Per.Users.Export': 'Xuất user',
  'Per.Users.View': 'Xem user',
  'roles.create': 'Tạo vai trò',
  'roles.information': 'Thông tin vai trò',
  'roles.update': 'Chỉnh sửa vai trò',
  'Mes.Users.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Roles.Add.Successfully': 'Thêm vai trò thành công',

  'roles.createdBy': 'Tạo bởi',
  'roles.createDateTime': 'Ngày tạo',
  'roles.internalComment': 'Ghi chú',
  'roles.isActive': 'Trạng thái hoạt động',
  'roles.delete': 'Xoá vai trò',
  'roles.content.delete': 'Bạn có chắc chắn muốn xóa vai trò này không?',
  'roles.add.name': 'Thêm mới vai trò',
  'roles.info.name': 'Thông tin vai trò',
  'roles.edit.name': 'Cập nhật vai trò',

  'roles.code': 'Mã vai trò',
  'roles.numberOfAssign': 'Số tài khoản được phân quyền',

  'roles.delete.title': 'Xoá vai trò',
  'roles.delete.content': 'Bạn có chắc chắn muốn xóa vai trò này không?',
  'users.role': 'Vai trò',

  EventsCreate: 'Thêm mới sự kiện',

  EventsDelete: 'Xóa sự kiện',

  EventsUpdate: 'Cập nhật sự kiện',

  EventsView: 'Xem sự kiện',

  Events: 'Sự kiện',

  Report:'Báo cáo',
  Reports:'Báo cáo',

  GiftsCreate: 'Thêm mới quà tặng',

  GiftsDelete: 'Xóa quà tặng',

  GiftsUpdate: 'Cập nhật quà tặng',

  GiftsView: 'Xem quà tặng',
  Gifts: 'Quà tặng',

  QuestionsCreate: 'Thêm mới câu hỏi',

  QuestionsDelete: 'Xóa câu hỏi',

  QuestionsUpdate: 'Cập nhật câu hỏi',

  QuestionsView: 'Xem câu hỏi',
  Questions: 'Câu hỏi',

  QuizesCreate: 'Thêm mới câu đố',
  Quizes: 'Câu đố',

  QuizesReply: 'Trả lời câu đố',

  RolesCreate: 'Thêm mới vai trò',
  Roles: 'Vai trò',

  RolesDelete: 'Xóa vai trò',

  RolesUpdate: 'Cập nhật vai trò',

  RolesView: 'Xem vai trò',

  SessionsCreate: 'Thêm mới phiên',
  Sessions: 'Phiên',

  SessionsView: 'Xem phiên',

  UsersCreate: 'Thêm mới người dùng',
  Users: 'Người dùng',
  UsersDelete: 'Xóa người dùng',

  UsersUpdate: 'Cập nhật người dùng',

  UsersView: 'Xem người dùng',
  ReportView: 'Xem báo cáo'
};
