import React from 'react';

import { IRouter } from '@routers/interface';
import { PermissionEnum } from '@modules/permissions/entity';
import { SecurityScanOutlined } from '@ant-design/icons';

export const routerRoles: IRouter = {
  path: '/roles',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'roles.title', //translate here for breadcrumb and sidebar
  permissionCode: PermissionEnum.RolesView,
  menu: {
    icon: <SecurityScanOutlined />,
  },
};
