import { routerLogin } from '@view/Auth/Login/router';
import { routerViewProfile } from '@view/Auth/Profiles/router';
import { routerEventGift } from '@view/Events/Gift/router';
import { routerEventQuestions } from '@view/Events/questions/router';
import { routerEvents } from '@view/Events/router';
import { routerGift } from '@view/Gift/router';
import { routerHome } from '@view/Home/router';
import { routerPageError } from '@view/PageError/router';
import { routerQuestions } from '@view/Questions/router';
import { routerUsers } from '@view/Users/router';

import { IRouter } from './interface';
import { routerRoles } from '@view/Roles/router';
import { routerEventReport } from '@view/Report/router';

export const privatePage: IRouter[] = [
  routerHome,
  routerEvents,
  routerQuestions,
  routerGift,
  routerUsers,
  routerEventQuestions,
  routerEventGift,
  routerRoles,
  routerViewProfile,
  routerEventReport,
  routerPageError,
];

export const publicPage: IRouter[] = [routerLogin, routerPageError];
