export default {
  'gift.module.name': 'Quản lý quà tặng',
  'gift.title': 'Quản lý quà tặng',
  'gift.name': 'Tên quà tặng',
  'gift.thumbnail': 'Hình ảnh',
  'gift.quantity': 'Tổng số lượng',
  'gift.usedAmount': 'Đã sử dụng',
  'gift.amount': 'Còn lại',
  'gift.createdAt': 'Ngày tạo',
  'gift.action': 'Thao tác',
  'gift.create': 'Thêm quà tặng',
  'gift.status': 'Trạng thái họat động',
  'gift.update': 'Chỉnh sửa quà tặng',
  'gift.delete': 'Xóa quà tặng',
  'events.gift.title.reset': 'Reset kho quà tặng',
  'events.gift.content.reset':
    'Số lượng phần quà đã sử dụng của chương trình sẽ trở về không. Bạn có muốn tiếp tục không?',
  'gift.reset': 'Reset kho quà tặng',
  'gift.information': 'Thông tin quà tặng',
  'gift.content.delete':
    'Những quà tặng được chọn sẽ bị xóa và không thể khôi phục. Bạn có muốn tiếp tục?',
  'gift.usedQuantity': 'Đã sử dụng',
  'gift.type': 'Loại quà tặng',
  'gift.type.params':
    '{status,select, 1 {Áo} 2 {Găng tay chống nắng} 3 {Khẩu trang} 4 { Móc khoá} 5 {Nón} other {Không xác định}}',
  'events.questions.content.delete':
    'Những câu hỏi được chọn sẽ bị xoá khỏi bản khảo sát. Bạn có muốn tiếp tục không?',
  'events.gift.content.delete':
    'Những phần quà được chọn sẽ bị xoá khỏi phiên khảo sát. Bạn có muốn tiếp tục không?',

  'events.name': 'Tên sự kiện',
  'events.title': 'Quản lý sự kiện',
  'events.delete': 'Xóa sự kiện',
  'events.content.delete':
    'Những sự kiện được chọn sẽ bị xóa và không thể khôi phục. Bạn có muốn tiếp tục?',
  'events.update': 'Chỉnh sửa sự kiện',
  'events.create': 'Thêm sự kiện',
  'events.action': 'Thao tác',
  'events.description': 'Mô tả',
  'events.id': 'Mã sự kiện',
  'events.information': 'Thông tin sự kiện',
  'events.createdAt': 'Ngày tạo',
  'events.code': 'Mã sự kiện',
  'gift.score': 'Khoảng điểm',
  'gift.winRate': 'Tỷ lệ',
  'events.add.gift': 'Thêm quà tặng',
  'events.add.question': 'Thêm câu hỏi',

  'events.add.gift.require': 'Không được để trống',
  'events.add.gift.name': 'Tên quà tặng',
  'events.add.gift.quantity': 'Số lượng',
  'events.add.gift.score': 'Khoảng điểm',
  'events.add.gift.winRate': 'Tỷ lệ',

  'questions.title': 'Quản lý câu hỏi',
  'questions.createdAt': 'Ngày tạo',
  'questions.create': 'Thêm câu hỏi',
  'questions.information': 'Thông tin câu hỏi',
  'questions.action': 'Thao tác',
  'questions.delete': 'Xóa câu hỏi',
  'questions.content.delete':
    'Những câu hỏi được chọn sẽ bị xóa và không thể khôi phục. Bạn có muốn tiếp tục?',
  'questions.content': 'Nội dung câu hỏi',
  'questions.illustrationPath': 'Hình ảnh',
  'questions.description': 'Mô tả',
  'questions.type': 'Loại đáp án',
  'questions.numberAnswer': 'Số lượng đáp án',
  'questions.update': 'Chỉnh sửa câu hỏi',
  'questions.type.params':
    '{status,select, 0 {Câu hỏi tự do} 1 {Câu hỏi sắp xếp} 2 {Câu hỏi 1 đáp án} 3 {Câu hỏi nhiều đáp án} other {Không xác định}}',
  'questions.type.1': 'Câu hỏi sắp xếp',
  'questions.type.2': 'Câu hỏi 1 đáp án',
  'questions.type.3': 'Câu hỏi nhiều đáp án',
  'questions.logo': 'Logo',
  'questions.logoPath': 'Logo',
  'questions.sequence': 'Loại câu hỏi',
  'questions.Sequence.params':
    '{status,select, 1 {Loại 1} 2 {Loại 2} 3 {Loại 3} other {Không xác định}}',
  'questions.answer.status': 'Đáp án đúng',
  'common.add.answer': 'Thêm đáp án',
  'questions.answer.content': 'Nội dung đáp án',
  'questions.answer.description': 'Mô tả đáp án',
  "events.add.gift.scoreForm":"Điểm thấp nhất",
  "events.add.gift.scoreTo":"Điểm cao nhất"
};
