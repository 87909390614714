import React from 'react';
import { IRouter } from '@routers/interface';
import { UserOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerEventReport: IRouter = {
  path: '/events/:eventId/report',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'report.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.ReportView,
};


export const routerReport: IRouter = {
  path: '/report',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'report.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UserOutlined />,
  },
};