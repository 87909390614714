export default {
  'setting.name': 'Setting',
  'roles.title': 'Role management',
  'roles.name': 'Name of role',
  'roles.rolePermissions': 'Permissions',
  'roles.permissionCodes': 'Permissions',
  'roles.createdAt': 'Created at',
  'roles.action': 'Action',
  'Per.Users.Create': 'Add user',
  'Per.Users.Delete': 'Delete user',
  'Per.Users.Edit': 'Edit user',
  'Per.Users.Export': 'Export user',
  'Per.Users.View': 'View user',
  'roles.create': 'Create role',
  'roles.information': 'Role information',
  'roles.update': 'Edit role',
  'Mes.Users.Role.UpdateSuccessfully': 'Update role successfully',
  'Mes.Roles.Add.Successfully': 'Create role successfully',
  'roles.code': 'Role code',
  'roles.numberOfAssign': 'The number of accounts is assigned',

  'roles.delete.title': 'Delete the role',
  'roles.delete.content': 'Are you sure want to delete this Role?',
  'users.role': 'Role',


  EventsCreate: 'Add new event',

  EventsDelete: 'Delete events',

  EventsUpdate: 'Event update',

  EventsView: 'See events',

  GiftsCreate: 'Add new gifts',

  GiftsDelete: 'Delete gifts',

  GiftsUpdate: 'Gift update',

  GiftsView: 'View gifts',

  QuestionsCreate: 'Add new questions',

  QuestionsDelete: 'Delete questions',

  QuestionsUpdate: 'Question update',

  QuestionsView: 'See questions',

  QuizesCreate: 'Add new puzzles',

  QuizesReply: 'Answer the puzzle',

  RolesCreate: 'Add a new role',

  RolesDelete: 'Delete the role',

  RolesUpdate: 'Update the role',

  RolesView: 'See the role',

  SessionsCreate: 'Add new session',

  SessionsView: 'See session',

  UsersCreate: 'Add new users',

  UsersDelete: 'Delete user',

  UsersUpdate: 'Update user',

  UsersView: 'View User',
  Events: 'Event',
  Gifts: 'Present',
  Questions: 'Question',
  Quizes: 'Riddle',
  Roles: 'Role',
  Users: 'User',
  Sessions: 'Session',
};
