import React from 'react';
import { IRouter } from '@routers/interface';
import { GroupOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerEvents: IRouter = {
  path: '/events',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'events.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <GroupOutlined />,
    activePath: /events/gi,
  },
  permissionCode: PermissionEnum.EventsView,
};
