import React from 'react';
import { IRouter } from '@routers/interface';


export const routerEventQuestions: IRouter = {
  path: '/events/:eventId/questions',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'questions.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
 
};