import React from 'react';
import { IRouter } from '@routers/interface';
import { UserOutlined } from '@ant-design/icons';
import { PermissionEnum } from '@modules/permissions/entity';

export const routerUsers: IRouter = {
  path: '/users',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'users.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UserOutlined />,
  },
  permissionCode: PermissionEnum.UsersView,
};
