import React from 'react';
import { IRouter } from '@routers/interface';


export const routerEventGift: IRouter = {
  path: '/events/:eventId/gift',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'gift.title', //translate here for breadcrumb and sidebar
  masterLayout: true,
 
};