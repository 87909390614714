import RoleEntity from '@modules/roles/entity';
import { UploadFile } from 'antd';
import moment from 'moment';

export class UserPermissionEntity {
  accountPermissionId: string = '';

  permissionCode = '';

  accountId = '';

  accountPermissionCreateAt = '';

  constructor(permission: Partial<UserPermissionEntity>) {
    if (!permission) {
      return;
    }
    Object.assign(this, permission);
  }

  static createlistPermisison(list?: Array<Partial<UserPermissionEntity>>) {
    if (list === undefined) {
      return undefined;
    }
    return list.map(ll => {
      return new UserPermissionEntity(ll);
    });
  }
}
class UserEntity {
  username = '';

  name = '';

  permissions: UserPermissionEntity[] = [];

  id = '';

  createdAt?: any;

  roleId?: any;

  role?: RoleEntity;

  avatar?: string = '';

  fileAvatar?: UploadFile[] = [];

  dayOfBirth?: string;

  status: number = 1;

  emailAddress: string = '';

  constructor(user?: Partial<UserEntity>) {
    if (!user) {
      return;
    }
    Object.assign(this, user);
    this.permissions = UserPermissionEntity.createlistPermisison(user?.permissions) || [];
    this.createdAt = user?.createdAt ? moment(user?.createdAt).format('DD/MM/YYYY HH:mm:ss') : '';

    this.dayOfBirth = user?.dayOfBirth
      ? moment(user?.dayOfBirth).format('DD/MM/YYYY HH:mm:ss')
      : '';
  }

  static createArrayUser(arrUser: Array<Partial<UserEntity>>): Array<UserEntity> {
    if (!arrUser) {
      return [];
    }
    return arrUser.map(x => new UserEntity(x));
  }
}

export default UserEntity;
